<template>
	<div >
		
		<div class="ft20 cl-black cl-main ftw500">商品管理</div>
		
		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="商品名称">
						<a-input v-model="search.name" placeholder="请输入商品名称"></a-input>
					</a-form-item>
		
					<a-form-item label="销量">
						<a-input v-model="search.bg_take_count" style="width: 150px"></a-input>
						-
						<a-input v-model="search.end_take_count" style="width: 150px"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 600px;">
				<div class="flex alcenter">
					<a-button type="primary" icon="plus" @click="addGoodsAct()">添加商品</a-button>
				</div>
				
				<div class="mt30">
					<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
						<a-radio-button :value="1">
						  上架中
						</a-radio-button>
						<a-radio-button :value="2">
						  已售罄
						</a-radio-button>
						<a-radio-button :value="3">
						  已下架
						</a-radio-button>
					</a-radio-group>
				</div>
				
				
				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="goods_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							<div class="flex  " slot="goods" slot-scope="record">
								<div class="goods-index-cover-img">
									<img v-if="record.cover_img != null" :src="record.cover_img"/>
									<span class="tag" v-if="record.is_tui==1">首推</span>
								</div>
								<div class="ml10 flex alcenter">
									<div class="ft14 ftw500 cl-info " style="text-align: left;">
										<span class="manage-goods-item tag" v-if="record.is_open_yu==1">预售</span>
										{{record.name}}
									</div>
								</div>
							</div>
										
							<div class="flex alcenter center" slot="vip_price" slot-scope="vip_price,record">
								¥{{vip_price}}
							</div>
							
							<div class="flex alcenter center" slot="original_price" slot-scope="original_price,record">
								¥{{original_price}}
							</div>
					
							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editGoodsAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											  </a-menu-item>
											  <a-menu-item v-if="record.status==1" >
												<a class="menu-act" href="javascript:;" @click="undercarriage(record)">
													<i class="iconfont ft14 iconxiajia"></i>
													<span class="ml10">下架</span>
												</a>
											  </a-menu-item>
											  <a-menu-item v-if="record.status==0">
													<a class="menu-act" href="javascript:;" @click="grounding(record)">
														<i class="iconfont ft14 iconshangjia"></i>
														<span class="ml10">上架</span>
													</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
	</div>
				
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
        currentPage:Number(sessionStorage.getItem('mGoodscurrentPage')) || 1,
        pagination: {
          current: Number(sessionStorage.getItem('mGoodscurrentPage')) || 1, // 默认当前页数
          pageSize: 10, //每页中显示10条数据
          total: 0,
          onChange: (current) => {
            this.currentPage = current;
          }
        },
				search: {
					name: '',
					bg_take_count: '',
					end_take_count: '',
					status:1,
				},
				columns: [
					{title: '商品编号',dataIndex: 'goods_id',align: 'center',ellipsis: true},
					{title: '商品',key: 'goods',align: 'center',width:400,scopedSlots: {customRender: 'goods'}},
					{title: '原价',dataIndex: 'original_price',align: 'center',scopedSlots: {customRender: 'original_price'}},
					{title: '会员价',dataIndex: 'vip_price',align: 'center',scopedSlots: {customRender: 'vip_price'}},
					{title: '销量',dataIndex: 'take_count',align: 'center',ellipsis: true},
					{title: '库存',dataIndex: 'quota',align: 'center',ellipsis: true},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getGoodsList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
					bg_take_count:this.search.bg_take_count,
					end_take_count:this.search.end_take_count,
					status:this.search.status,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			undercarriage(record){
				this.$confirm({
					title:'确认下架这个商品吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/undercarriageGoods',{
								goods_id:record.goods_id,
							}).then(res=>{
								this.$message.success('下架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			grounding(record){
				this.$confirm({
					title:'确认上架这个商品吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/groundingGoods',{
								goods_id:record.goods_id,
							}).then(res=>{
								this.$message.success('上架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			changeStatus(){
				this.getLists();
			},
			
			addGoodsAct(){
        sessionStorage.setItem('mGoodscurrentPage',this.currentPage);
				this.$router.push('/mall/editGoods');
			},
			
			editGoodsAct(record){
        sessionStorage.setItem('mGoodscurrentPage',this.currentPage);
				this.$router.push('/mall/editGoods?id='+record.goods_id);
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
        sessionStorage.setItem('mGoodscurrentPage',pagination.current);
				this.getLists();
			},
			
		}
	}
</script>

<style>
	.manage-goods-left{
		width: 300px;
		min-height: 100vh;
	}
	
	.goods-index-table img{
		width: 80px;
		height: 60px;
	}
	
	.goods-index-cover-img{
		width: 60px;
		height: 60px;
		position: relative;
	}
	.goods-index-cover-img img{
		width: 60px;
		height: 60px;
	}
	.goods-index-cover-img .tag{
		position: absolute;
		top:0px;
		left: 0px;
		padding: 0px 4px;
		background: #FF6600;
		
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
	}
	
	.manage-goods-item.tag{
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}
</style>
